import React from 'react';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import Topbar from '../../components/dashboard/Topbar';
import Sidebar from '../../components/dashboard/Sidebar';
import Breadcrumbs from './Breadcrumbs';
import DocsHeader from './DocsHeader';
import AnnualReport from '../../documents/AnnualReport';

const Annuals = () => {
  const { profile } = useContext(UserContext);
  const uniqueReports = [];

  if (profile && profile.length > 0) {
    const currentYear = new Date().getFullYear(); // Get the current year
    profile.forEach((profileItem) => {
      const reportMap = new Map();
  
      if (profileItem.investments && profileItem.investments.length > 0) {
        profileItem.investments.forEach((investment) => {
          // Check for orders with paid transactions
          const hasPaidTransaction = investment.orders?.some((order) => 
            order.transactions?.some((transaction) => transaction.status === "paid")
          );
  
          if (hasPaidTransaction) {
            const year = new Date(investment.investment_created_date).getFullYear();
  
            // Only process investments from previous years
            if (year < currentYear) {
              const key = `${profileItem.profile_id}_${year}_${investment.pension}`;
              if (!reportMap.has(key)) {
                reportMap.set(key, {
                  profileId: profileItem.profile_id,
                  profileName: profileItem.profile_name,
                  year: year,
                  pension: investment.pension,
                });
              }
            }
          }
        });
      }
  
      uniqueReports.push(...reportMap.values());
    });
  }

  return (
    <div id="dashboard">
      <Topbar />
      <Sidebar />

      <div className="board">
        <Breadcrumbs />

        <div className="card card-full">
          <div className="card-body">
            <DocsHeader />

            {uniqueReports.map((report) => (
              <AnnualReport
                key={`${report.profileId}_${report.year}_${report.pension}`}
                profileId={report.profileId}
                profileName={report.profileName}
                year={report.year}
                pension={report.pension}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Annuals;