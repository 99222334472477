import React  from 'react';
import {useState} from 'react'
import axios from 'axios'




const AnnualReport = (props) => {

    const [wait, setWait] = useState(false);

   

    const year = props.year;
 
 
   
    const handleDownload = async () => {
        try {
            const response = await axios.post(
                'https://admin.capler.nl/filehandler/generate.php',
                {
                    type: 'annual',
                    filename: 'Jaaroverzicht ' + props.profileName,
                    profile_id: props.profileId,
                    pension: props.pension,
                    year: props.year,
                },
                { responseType: 'arraybuffer' }
            );
    
            if (response.status === 200) {
                const fileBlob = new Blob([response.data], {
                    type: "application/pdf",
                });
    
                const fileUrl = URL.createObjectURL(fileBlob);
    
                // Create a temporary link element
                const downloadLink = document.createElement("a");
                downloadLink.href = fileUrl;
                downloadLink.download = `Jaaroverzicht_${props.profileName}_${props.year}.pdf`; // Dynamic file name
                document.body.appendChild(downloadLink); // Add to DOM
                downloadLink.click(); // Trigger download
                document.body.removeChild(downloadLink); // Clean up
                URL.revokeObjectURL(fileUrl); // Release memory
            } else {
                console.error("Error downloading file:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    }; 


    return (

        
    
        <div className="file">
            <div className="d-flex aic mw-60">
                <h3 className="muted"><i className="bi bi-file-text"></i> </h3>
                <p className="ellipsis">Jaaroverzicht {year} {props.pension === '1' && '(lijfrente)' } - {props.profileName}</p>
                
            </div>   
         
            <p>01 jan {year + 1}</p>
            {props.nav_date === 1 ?
            <a target="_blank" download href={props.document.dir} className="btn">Download</a>
            :
            <button onClick={handleDownload} className="btn">Download</button>
            }
        </div>

        

    )
}

export default AnnualReport;