import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import {useContext, useState} from 'react'
import {UserContext} from '../context/UserContext'
import Wallet from './portfolio/Wallet';
import Transactions from './portfolio/Transactions';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Redirecting from '../components/Redirecting';
import React from "react";
import axios from 'axios';
export const Axios = axios.create({
    baseURL: 'https://admin.capler.nl/',
  });



const Portfolio = () => {

    const {user} = useContext(UserContext);    
    const [isLoading, setIsLoading] = useState(false);
    const [checkout, setCheckout] = useState([]);
    const [validated, setValidated] = useState(false);
    const [wait, setWait] = useState(false);
    const [url, setUrl] = useState([]);
    const [payment, setPayment] = useState([]);
    const [formData, setFormData] = useState({});

     
 const handleCreatePayment = async (e, orderId, orderExt, orderAmount, recurring, investmentId) => {
    e.preventDefault();
    setWait(true);
    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      e.preventDefault();
      setValidated(true);
  
    if(!Object.values(formData).every(val => val.trim() !== '')){
        
        return;
    }
  
    const data = await createPayment(orderId, orderExt, orderAmount, recurring, investmentId);
    if(data.success){
        e.target.reset();
        
        
    }
    else if(!data.success && data.message){
        
    }
    
  }

 

 
  
  const createPayment = async (orderId, orderExt, orderAmount, recurring, investmentId) => {
  
    
    try{
        setIsLoading(true);
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        const {data} = await Axios.post(recurring === 1 ? 'mollie/create_first_payment.php' : 'mollie/create_payment.php',{
          user_id:user.user_id,
          order_id:orderId,
          order_id_ext:orderExt,
          order_amount:Number(orderAmount),
          investment_id: investmentId,
          user_email: user.email,
          user_firstname: user.first_name,
          user_lastname: user.last_name

        }).then(response => response.data).then((d) => {
          setCheckout(d);
         window.location.replace(d.checkoutUrl); // Move the redirect code here
        });
      
    
        setIsLoading(false);
        
       // setErrMsg(false);
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
  
    
  }

if (isLoading) {
    return <Redirecting />;
  }

    return (

        

        <div id="dashboard">

            <Topbar/>   
            <Sidebar/> 

            <div className="board">

            {user.has_investments === 'yes' ? <> 

            <Wallet handleCreatePayment={handleCreatePayment}/> 
            <Transactions/> 

            </> 
            :
            <div className="card card">
                 <div className="card-body">
                 <h3 className="text-left">Nog geen beleggingen</h3>
                 <p>Zodra u een belegging heeft gedaan ziet u hier een overzicht van uw beleggingen.</p>
                 {!user.id_first_name ?
                    <Link className="card-btn" to='/invest'><Button variant="primary">Start uw eerste belegging</Button></Link>
                    :
                    <Link className="card-btn" to='/invest'><Button variant="primary">Vervolg uw eerste belegging</Button></Link>
                    }
                </div>
            </div>
            }

            </div>
            
            
        </div>

    )
}

export default Portfolio;
