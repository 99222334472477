import {useState,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import Topbar from '../components/dashboard/Topbar';
import Sidebar from '../components/dashboard/Sidebar';
import Breadcrumbs from '../pages/folders/Breadcrumbs';
import React from "react";
import PdfDocument from '../documents/PdfDocument'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
 
export default function Documents() {

    const {documents, text, user, profile} = useContext(UserContext);


    const numDescending = [...documents].sort((a, b) => b.order_date - a.order_date);

    let navigate = useNavigate()
    if (user.has_investments !== 'yes') {
        navigate('/documents/fund');
    }

    const currentYear = new Date().getFullYear(); // Get the current year
    let hasTransactionsInPrevYears = false;  // Initialize the variable

    if (profile && profile.length > 0) {
      profile.forEach((profileItem) => {
        if (profileItem.investments && profileItem.investments.length > 0) {
          profileItem.investments.forEach((investment) => {
            // Check for orders with paid transactions
            const hasPaidTransaction = investment.orders?.some((order) => 
              order.transactions?.some((transaction) => transaction.status === "paid")
            );

            if (hasPaidTransaction) {
              const year = new Date(investment.investment_created_date).getFullYear();
              
              // If any investment is from a year before the current year, set the flag to true
              if (year < currentYear) {
                hasTransactionsInPrevYears = true;
              }
            }
          });
        }
      });
    }

    const hasAcceptedOrders = (profile) => {
        if (profile && profile.length > 0) {
          const orders = profile[0].investments.reduce((acc, investment) => {
            if (investment.orders && investment.orders.length > 0) {
              const acceptedOrders = investment.orders.filter(
                (order) => order.order_status === "accepted"
              );
              return [...acc, ...acceptedOrders];
            }
            return acc;
          }, []);
      
          return orders.length > 0;
        }
      
        return false;
      };
 
 
  return (

<div id="dashboard">

    <Topbar/>   
    <Sidebar/> 
    
    <div className="board">

    <Breadcrumbs/>
    

   
    <Link className="card card-fixed" to="/documents/fund">
   
        <div className="card-body">
        <div className="d-flex aic">
		<h3 className="muted"><i className="bi bi-folder"></i> </h3>
		<h5>Fonds</h5>
		</div>
            
        </div>
    
    </Link>

    {user.has_investments === 'yes' && (
    <>
    {profile.slice(0, 1).map((participatedProfile, index) => (
            <>
            {participatedProfile.investments.slice(0, 1).map((profileInvestments, index) => (
                
            <>
    <Link className="card card-fixed" key={profileInvestments.id} to="/documents/contracts">
        <div className="card-body">
        <div className="d-flex aic">
		<h3 className="muted"><i className="bi bi-folder"></i> </h3>
		<h5>Inschrijvingen</h5>
		</div>
        </div>
        </Link>
    </>
            ))}
            </>
        ))}
    </>
    )}

{hasTransactionsInPrevYears ? (
    <Link className="card card-fixed" to="/documents/annuals">
        <div className="card-body">
        <div className="d-flex aic">
		<h3 className="muted"><i className="bi bi-folder"></i> </h3>
		<h5>Jaaroverzichten</h5>
		</div>
        </div>
        </Link>
     ) : (
      <></>
    )}

{hasAcceptedOrders(profile) ? (
        <Link className="card card-fixed" to="/documents/mutations">
            <div className="card-body">
                <div className="d-flex aic">
                    <h3 className="muted"><i className="bi bi-folder"></i> </h3>
                    <h5>Mutatieoverzichten</h5>
                </div>
            </div>
        </Link>
      ) : (
        <></>
      )}

{hasAcceptedOrders(profile) ? (
        <Link className="card card-fixed" to="/documents/updates">
            <div className="card-body">
                <div className="d-flex aic">
                    <h3 className="muted"><i className="bi bi-folder"></i> </h3>
                    <h5>Updates</h5>
                </div>
            </div>
        </Link>
      ) : (
        <></>
      )}


    
    </div>


</div>

    
  );
}