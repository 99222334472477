import React  from 'react';
import {useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {useState} from 'react'
import axios from 'axios'




const NewContract = ({investment}) => {

    const {user} = useContext(UserContext);

    const [wait, setWait] = useState(false);
   
    const handleDownload = async () => {
        try {
            const response = await axios.post(
                'https://admin.capler.nl/filehandler/generate.php',
                {
                    type: 'contract',
                    investment_id: investment.investment_id,
                    filename: 'Inschrijving',
                    user_id: user.user_id,
                },
                { responseType: 'arraybuffer' }
            );
    
            if (response.status === 200) {
                const fileBlob = new Blob([response.data], {
                    type: "application/pdf",
                });
    
                const fileUrl = URL.createObjectURL(fileBlob);
    
                // Create a temporary link element
                const downloadLink = document.createElement("a");
                downloadLink.href = fileUrl;
                downloadLink.download = "Inschrijving.pdf"; // Default filename, can be dynamic
                document.body.appendChild(downloadLink); // Add to DOM
                downloadLink.click(); // Trigger download
                document.body.removeChild(downloadLink); // Clean up
                URL.revokeObjectURL(fileUrl); // Release memory
            } else {
                console.error("Error downloading file:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    }; 


    return (

        
    
        <div className="file">
            <div className="d-flex aic mw-60">
                <h3 className="muted"><i className="bi bi-file-text"></i> </h3>
                <p>Inschrijving {investment.recurring === '1' ? 'maandelijks' : 'eenmalig'} {investment.pension === '1' ? 'pensioen' : 'regulier'}</p>
                
            </div>   
         
            <p>{investment.investment_preview_date}</p>
           
            <button onClick={handleDownload} className="btn">Download</button>
            
        </div>

        

    )
}

export default NewContract;