import React  from 'react';
import {useState,useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export const Axios = axios.create({
  baseURL: 'https://admin.capler.nl/',
});






const ProfileWallets = ({participatedProfile, pension, handleCreatePayment}) => {

  
   const {loggedInCheck} = useContext(UserContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [checkout, setCheckout] = useState([]);
    const [validated, setValidated] = useState(false);
    const [wait, setWait] = useState(false);
    const [url, setUrl] = useState([]);
    const [payment, setPayment] = useState([]);
    const [formData, setFormData] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [formError, setFormError] = useState(false);
    const [investmentIdToCancel, setInvestmentIdToCancel] = useState(null);
  
    // Function to handle showing the modal and passing the order_id
    const handleShowModal = (investmentId) => {
      setInvestmentIdToCancel(investmentId); // Set the order ID to cancel
      setShowModal(true); // Show the modal
    };
  
    // Function to close the modal
    const handleCloseModal = () => {
      setShowModal(false);
      setCancelReason(""); // Clear cancel reason
      setFormError(false); // Reset form error state
      setInvestmentIdToCancel(null); // Clear the investmentId state after closing the modal
    };
  
    // Function to handle order cancellation
    const handleConfirmCancel = () => {
      if (!cancelReason.trim()) {
        setFormError(true); // Show error if the reason is empty
        return;
      }
  
      // Call the cancel order function with the orderId passed via onClick
      handleCancelInvestment(investmentIdToCancel, cancelReason);
      handleCloseModal(); // Close the modal
    };
  
    // Placeholder for the actual cancel order function (replace with your actual logic)
    const handleCancelInvestment = async (investmentId, reason) => {
      console.log(`Investment ID: ${investmentId} is canceled for reason: ${reason}`);
      // Actual cancellation logic here
      try{
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        const {data} = await Axios.post('investment/delete.php',{
          investment_id: investmentId,
          reason: reason,
        }).then(response => response.data);

        
        
        navigate('/portfolio');
        await loggedInCheck();
        
        setWait(false);
        
        
  
    }
    catch(err){
        setWait(false);
        return {success:0, message:'Server Error!'};
    }
    };

    const popover_net_return = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Totaal rendement</Popover.Header>
          <Popover.Body>
          In het ‘totaal rendement’ zijn de instapkosten meegenomen, zodat u een eerlijk beeld krijgt van de werkelijke opbrengst van uw belegging. Dit betekent dus niet noodzakelijk dat uw participaties in waarde zijn gedaald.
          </Popover.Body>
        </Popover>
      );

    const popover_part_amount = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Aantal participaties</Popover.Header>
          <Popover.Body>
          Het aantal participaties dat u ontvangt, wordt bepaald bij acceptatie van uw belegging. Het aantal hangt af van uw inleg en de op dat moment geldende prijs per participatie.
          </Popover.Body>
        </Popover>
      );

      const popover_part_value = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Waarde per participatie</Popover.Header>
          <Popover.Body>
          De waarde per participatie is de prijs van één participatie (aandeel) in het fonds. Deze wordt bepaald door de waarde van het gehele fonds gedeeld door het aantal participaties.
          </Popover.Body>
        </Popover>
      );

    const openOrders = participatedProfile.investments[0]?.orders.filter(order => order.order_status === 'open');
const openOrdersAlt = participatedProfile.investments[1]?.orders.filter(order => order.order_status === 'open');
const defaultActiveKey = openOrders && openOrders.length > 0 ? openOrders[0]?.order_id : openOrdersAlt && openOrdersAlt.length > 0 ? openOrdersAlt[0]?.order_id : null;


    const {user, text, funds} = useContext(UserContext);

    var valueAmount = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_value_amount 
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_value_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_value_amount
    : "" ;

    var valueAmountPart = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_value_amount_part 
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_value_amount_part
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_value_amount_part
    : "" ;

    var profileOrderedAccepted = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_ordered_accepted
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_ordered_accepted
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_ordered_accepted
    : "" ;

    var profileAccepted = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_accepted_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_accepted_amount
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_accepted_amount
    : "" ;

    var profileBrutReturn = (participatedProfile.pers_bus === 'bus') ? 
    participatedProfile.profile_total_return
    : (participatedProfile.pers_bus === 'pers' && pension === 'no') ?
    participatedProfile.reg_total_return
    : (participatedProfile.pers_bus === 'pers' && pension === 'yes') ?
    participatedProfile.pension_total_return
    : "" ;

    const profileBrutReturnPercent = (profileBrutReturn / profileAccepted) * 100;

    const profileNetReturn = valueAmount - profileOrderedAccepted;

    const profileNetReturnPercent = (profileNetReturn / profileOrderedAccepted) * 100;

    function addMoney(e) {
        e.preventDefault();
        localStorage.setItem('setProgress', 'profile');
        localStorage.setItem('selectedMethod', 'regular');
        localStorage.setItem('selectedProfile', e.target.dataset.letter);
        navigate("/invest")
      };

     

    return (
        
       
        <div className="card card-full">
            
            <div className="card-body">
            <div className="card-top">
                    <div className="left">
                <h4 className="text-left">{participatedProfile.profile_name}</h4>
               {participatedProfile.pers_bus === 'pers' && pension === 'no' ?
               <p className="sub">Privé beleggen</p>
               : participatedProfile.pers_bus === 'pers' && pension === 'yes' ?
               <p className="sub">Pensioen beleggen</p>
                : <p className="sub">Zakelijk beleggen</p>
                }
                </div>
               
                <Link key={participatedProfile} data-letter={participatedProfile.profile_id} onClick={addMoney} className="btn hide-phone"><i className="bi bi-plus"></i> Nieuwe belegging</Link>
            </div>


       {valueAmount > 0 && (
        
                <>
               
                <div className="investments">
                    
                <div className='card w-100'>
                    <div className='card-body'>
                        
               
                 <h1>€<NumericFormat value={valueAmount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h1>
                
                
                
                <p className="muted small">Totale waarde</p>

                    </div>
                </div>

                <hr className="little-line"/>
                
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 >€<NumericFormat value={profileAccepted.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Netto belegd</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileBrutReturn > 0 && 'text-success'}>{profileBrutReturn > 0 && '+'}€<NumericFormat value={profileBrutReturn.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileBrutReturnPercent > 0 && 'text-success'}>{profileBrutReturnPercent > 0 && '+'}<NumericFormat value={profileBrutReturnPercent.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />%</h5>
                <p className="muted small">Behaald rendement</p>

                    </div>
                </div>

                <div className='card'>
                    <div className='card-body'>
                        
                <h5 >€<NumericFormat value={profileOrderedAccepted.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Totaal belegd</p>

                    </div>
                </div>
                <OverlayTrigger trigger="hover" overlay={popover_net_return} delay={{ show: 500, hide: 200 }}>
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileNetReturn > 0 ? 'text-success' : 'text-danger'}>{profileNetReturn > 0 && '+'}€<NumericFormat value={profileNetReturn.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                <p className="muted small">Totaal rendement</p>

                    </div>
                </div>
                </OverlayTrigger>
                <OverlayTrigger trigger="hover" overlay={popover_net_return} delay={{ show: 500, hide: 200 }}>
                
                <div className='card'>
                    <div className='card-body'>
                        
                <h5 className={profileNetReturnPercent > 0 ? 'text-success' : 'text-danger'}>{profileNetReturnPercent > 0 && '+'}<NumericFormat value={profileNetReturnPercent.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} />%</h5>
                <p className="muted small">Totaal rendement</p>

                    </div>
                </div>
                </OverlayTrigger>

                </div>

                <div className="investments">
                    


                   
                
                    <OverlayTrigger trigger="hover"  overlay={popover_part_amount} delay={{ show: 500, hide: 200 }}>
                    <div className='card'>
                    <div className='card-body'>
                            <h5><NumericFormat value={valueAmountPart.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                            <p className="muted small">Aantal participaties</p>
                            </div>
                            </div>
                    </OverlayTrigger>
                

                  

               
                        <OverlayTrigger trigger="hover" overlay={popover_part_value} delay={{ show: 500, hide: 200 }}>
                        <div className='card'>
                        <div className='card-body'>
                                <h5>{profileNetReturn > 0 && '+'}€<NumericFormat value={funds[0].current_nav.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                                <p className="muted small">Huidige waarde per participatie</p>
                                </div>
                                </div>
                        </OverlayTrigger>
                  


                </div>

                </>
        
       )}
        {participatedProfile.profile_ordered_amount > 0 && (

<div className="orders">
<Accordion defaultActiveKey={defaultActiveKey} flush>
                 {participatedProfile.investments.slice().reverse().map((selectedInvestment, indexy) => (
                    <>
                    {((selectedInvestment.pension === '0' && pension === 'no') || (selectedInvestment.pension === '1' && pension === 'yes')) && (
                    <>
                     {selectedInvestment.orders.slice().reverse().map((selectedOrder, indexie) => (
                    <>
                    {selectedOrder.order_status && selectedOrder.order_status !== 'accepted'  && (
                <>
                
                 <Accordion.Item key={selectedOrder.order_id} eventKey={selectedOrder.order_id}>
                 <Accordion.Header>
                <div className="order">
                        
                        {selectedOrder.order_status === 'approved' ?
                            <div className="tt_icon bg-grurple hide-phone">
                              <h5><i className="bi bi-check-all"></i></h5>
                            </div>
                            : selectedOrder.order_status === 'paid' ?
                            <div className="tt_icon bg-grurple hide-phone">
                              <h5><i className="bi bi-check"></i></h5>
                            </div>
                            :
                            <div className="tt_icon bg-beige hide-phone">
                              <h5><i className="bi bi-clock"></i></h5>
                            </div>
                            }
                       
                        <div className="left">
                        
                            {selectedOrder.recurring === 'yes' ?
                             <h6>Maandelijkse belegging van {selectedOrder.order_created_date}</h6>
                            :
                            <h6>Eenmalige belegging van {selectedOrder.order_created_date}</h6>
                            }
                           
                            
                            
                                
                            
                           
                            {selectedOrder.order_status === 'approved' ?
                            <p className="muted small">Wordt verwerkt bij volgende uitgifte</p>
                            : selectedOrder.order_status === 'paid' ?
                            <p className="muted small">Inschrijving compleet & betaling ontvangen.</p>
                            : selectedOrder.order_status === 'open' ?
                            <p className="muted small">Transactie nog niet bevestigd. Zie betaalgegevens.</p>
                            :
                            <p className="muted small">In behandeling</p>
                            }
                           
                        </div>
                        <div className="right">
                            <h5>€<NumericFormat value={selectedOrder.order_amount.toFixed(2)} displayType="text" decimalScale={2} decimalSeparator={text.decimal_seperator} allowedDecimalSeparators={text.decimal_seperator} allowLeadingZeros thousandSeparator={text.thousand_seperator} /></h5>
                        </div>
                    </div>
               
                   
                   </Accordion.Header>
                   {selectedOrder.order_status === 'paid' ?
                   <Accordion.Body>
                    
                   <div className="card">
                   <div className="card-body">
                    <h6 className="mb-3">Inschrijving compleet & betaling ontvangen</h6>
                     <p className="">Elke eerste werkdag van de maand start het proces om inschrijvingen om te zetten in participaties. Dit kan tot 28 werkdagen duren. U ontvangt bericht van ons zodra uw inschrijving is geaccepteerd. {/* Uiterlijk {selectedOrder.order_acceptance_date}. */}</p>         
                          
                   </div>
                   </div>
         
         
                                     
         
                 </Accordion.Body>
                   : selectedOrder.order_status === 'open' ?
                   <Accordion.Body>

{selectedOrder.recurring === 'yes' ?
                    <>
                              <div className="card">
                              <div className="card-body">
                    
                                <h6 className="mb-3">Gegevens periodieke overboeking</h6>         
                                <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                                <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                                <p className="small muted mb-1 mt-2">Onder vermelding van uw contractnummer:</p> <p>REC-{selectedInvestment.investment_id_ext}</p>

                                <div className="btn-container d-flex aic">
                              {selectedOrder.order_amount < 50000 && (
                  
                              <Form className="full mt-3 ml-auto " onSubmit={(e) => handleCreatePayment(e, selectedOrder.order_id, selectedOrder.order_id_ext, selectedOrder.order_amount, selectedInvestment.recurring, selectedInvestment.investment_id)}>
                                              
                                              <button
                                              className="btn mt-3  btn-primary" 
                                              type="submit"
                              
                                              >
                                              Direct online betalen en machtigen
                                              </button>
                                              </Form>    
                            )}
                            <div className="mt-3">
                             <button
                              className="btn mt-3 btn-link float-start"
                              onClick={() => handleShowModal(selectedInvestment.investment_id)}
                            >
                              Annuleren
                            </button>
                            </div>
                            </div>
                              
      
                              </div>
                              </div>

                            {/* Cancel Order Modal */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                              <Modal.Title>Belegging annuleren</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>Weet u zeker dat u deze belegging wilt annuleren?</p>

                              <Form className="mt-3">
                                <Form.Group controlId="cancelReason">
                                  <Form.Label className="small muted">Om onze dienstverlening te verbeteren, horen we graag waarom u deze order annuleert (optioneel):</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                  />
                                  {/* Verwijder de invalid-feedback, omdat het veld optioneel is */}
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="link" onClick={handleCloseModal}>
                                Sluiten
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => handleConfirmCancel(cancelReason)} // Pas de functie aan om de reden door te geven
                              >
                                Order Annuleren
                              </Button>
                            </Modal.Footer>
                          </Modal>
                            </>

                            :
                            <>
                            <div className="card">
                            <div className="card-body">
                  
                              <h6 className="mb-3">Details handmatige overboeking</h6>         
                              <p className="small muted mb-1 mt-2">Bankrekeningnummer:</p> <p>NL25 RABO 0375 545 387</p>
                              <p className="small muted mb-1 mt-2">Ten name van:</p> <p>Stichting Alpha High Performance Fund - Capler PE</p>
                              <p className="small muted mb-1 mt-2">Onder vermelding van uw ordernummer:</p> <p>{selectedOrder.order_id_ext}</p>
                              <div className="btn-container d-flex aic">
                              {selectedOrder.order_amount < 50000 && (
                  
                              <Form className="full mt-3 ml-auto " onSubmit={(e) => handleCreatePayment(e, selectedOrder.order_id, selectedOrder.order_id_ext, selectedOrder.order_amount, selectedInvestment.recurring, selectedInvestment.investment_id)}>
                                              
                                              <button
                                              className="btn mt-3  btn-primary" 
                                              type="submit"
                              
                                              >
                                              Direct online betalen
                                              </button>
                                              </Form>    
                            )}
                            <div className="mt-3">
                             <button
                              className="btn mt-3 btn-link float-start"
                              onClick={() => handleShowModal(selectedInvestment.investment_id)}
                            >
                              Annuleren
                            </button>
                            </div>
                            </div>
                            </div>
                            </div>

                            {/* Cancel Order Modal */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                              <Modal.Title>Belegging annuleren</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>Weet u zeker dat u deze belegging wilt annuleren?</p>

                              <Form className="mt-3">
                                <Form.Group controlId="cancelReason">
                                  <Form.Label className="small muted">Om onze dienstverlening te verbeteren, horen we graag waarom u deze order annuleert (optioneel):</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                  />
                                  {/* Verwijder de invalid-feedback, omdat het veld optioneel is */}
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="link" onClick={handleCloseModal}>
                                Sluiten
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => handleConfirmCancel(cancelReason)} // Pas de functie aan om de reden door te geven
                              >
                                Order Annuleren
                              </Button>
                            </Modal.Footer>
                          </Modal>
                            </>

                            }
                 
         

        

                            

        </Accordion.Body>
        :
        <></>
        }
        <hr/>
                   </Accordion.Item>
                  
                   </>
           
                  )}
                </>

              
                 ))}
                 </>
                   )}
                   </>
            ))}
             </Accordion>
             </div>
            
           
     
    )
    }
   
</div>
    </div>
   

    )


}

export default ProfileWallets;