import React  from 'react';
import {useState} from 'react'
import axios from 'axios'




const MutationOverview = (props) => {

    const [wait, setWait] = useState(false);

   

    const dateParts = props.navDate.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    const forday = ("0" + day).slice(-2);
    
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);
    
    const formattedDate = `${firstDay.toLocaleDateString()} - ${lastDay.toLocaleDateString()}`;

    const formonth = firstDay.toLocaleString('nl-NL', { month: 'long' });
    const formonthshort = firstDay.toLocaleString('nl-NL', { month: 'short' });
    const foryear = firstDay.getFullYear();
   
    const handleDownload = async () => {
        try {
            const response = await axios.post(
                'https://admin.capler.nl/filehandler/generate.php',
                {
                    type: 'mutation',
                    profile_name: props.profileName,
                    filename: 'Mutatieoverzicht ' + props.profileName,
                    profile_id: props.profileId,
                    nav_id: props.navId,
                },
                { responseType: 'arraybuffer' }
            );
    
            if (response.status === 200) {
                const fileBlob = new Blob([response.data], {
                    type: "application/pdf",
                });
    
                const fileUrl = URL.createObjectURL(fileBlob);
    
                // Create a temporary link element
                const downloadLink = document.createElement("a");
                downloadLink.href = fileUrl;
                downloadLink.download = `Mutatieoverzicht_${props.profileName}.pdf`; // Dynamic file name
                document.body.appendChild(downloadLink); // Add to DOM
                downloadLink.click(); // Trigger download
                document.body.removeChild(downloadLink); // Clean up
                URL.revokeObjectURL(fileUrl); // Release memory
            } else {
                console.error("Error downloading file:", response.statusText);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };


    return (

        
    
        <div className="file">
            <div className="d-flex aic mw-60">
                <h3 className="muted"><i className="bi bi-file-text"></i> </h3>
                <p className="ellipsis">Mutatieoverzicht {props.profileName} - {formonth} {foryear}</p>
                
            </div>   
         
            <p>{forday} {formonthshort}</p>
            {props.nav_date === 1 ?
            <a target="_blank" download href={props.document.dir} className="btn">Download</a>
            :
            <button onClick={handleDownload} className="btn">Download</button>
            }
        </div>

        

    )
}

export default MutationOverview;